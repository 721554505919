<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card color="success" inline title="Cititor" class="px-5 py-3">
          <v-form>
            <v-container class="py-0" fluid>
              <v-row dense class="text-no-wrap" align="center">
                <v-col cols="12" sm="9" class="order-1 order-sm-0">
                  <v-row dense class="text-no-wrap" align="center">
                    <v-col cols="12">
                      <v-text-field
                        v-model="record.fields['200'][0].a[0].val"
                        :rules="validateValue('200', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'200a ' + $t('Titlu program')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="record.fields['200'][0].b[0].val"
                        :rules="validateValue('200', 'b')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'200b ' + $t('Titlu activitate')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="record.fields['200'][0].d[0].val"
                        :rules="validateValue('200', 'd')"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'200d ' + $t('Titlu proiect')"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="3" class="d-flex justify-center order-0 order-sm-1">
                  <add-image v-model="record.fields['856'][0].link" :width="200" :height="115" />
                </v-col>
              </v-row>
              <base-material-tabs
                :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
                color="primary"
                show-arrows
                center-active
                centered
                fixed-tabs
              >
                <v-tab>{{ $t("Detalii") }}</v-tab>
                <v-tab>{{ $t("Organizator") }}</v-tab>
                <v-tab>{{ $t("Public") }}</v-tab>
                <v-tab>{{ $t("Note") }}</v-tab>
                <v-tab :disabled="!record._id">{{ $t("Legături") }}</v-tab>
                <v-tab>{{ $t("Identificare") }}</v-tab>
                <v-tab-item>
                  <v-row class="mt-4" dense>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="record.fields['615'][0].a[0].val"
                        :rules="validateValue('615', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'615a ' + $t('Tip activitate')"
                        :items="dictionary.tipActivitate"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-select
                        v-model="record.fields['606'][0].a[0].val"
                        :rules="validateValue('606', 'a')"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'606a ' + $t('Categorie activitate')"
                        :items="dictionary.catgActivitate"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" md="6">
                      <s-autocomplete
                        v-model="record.fields['210'][0].a[0].val"
                        :rules="validateValue('210', 'a')"
                        :label="'210a ' + $t('Locul desfăşurării')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.210.a.val'"
                        :item-value="'fields.210.a.val'"
                        collection="ebib_marc_evn"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <span class="d-none">{{ render }}</span>
                      <s-date-time
                        v-model="record.fields['210'][0].d[0].val"
                        :rules="validateValue('210', 'd')"
                        :hide-details="hided ? 'true' : 'false'"
                        :dense="dense"
                        :label="'210d ' + $t('De la data')"
                        type="datetime"
                      />
                    </v-col>
                    <v-col cols="12" sm="4" md="3">
                      <span class="d-none">{{ render }}</span>
                      <s-date-time
                        v-model="record.fields['210'][0].f[0].val"
                        :rules="validateValue('210', 'f')"
                        :hide-details="hided ? 'true' : 'false'"
                        :dense="dense"
                        :label="'210f ' + $t('Până la data')"
                        type="datetime"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="pt-4">
                  <v-row v-for="(fld, ind) in record.fields['700']" :key="'700' + ind" dense>
                    <v-col cols="12" sm="6">
                      <span class="d-none">{{ render }}</span>
                      <s-autocomplete
                        v-model="fld.a[0].val"
                        :label="'700a ' + $t('Organizator persoană')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.700.a.val'"
                        :item-value="'fields.700.a.val'"
                        collection="ebib_marc_evn"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <s-autocomplete
                        v-model="fld['4'][0].val"
                        :label="'7004 ' + $t('Funcţie')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.700.4.val'"
                        :item-value="'fields.700.4.val'"
                        collection="ebib_marc_evn"
                      >
                        <template v-slot:append-outer>
                          <div class="d-inline-flex">
                            <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.700', ind + 1)">
                              mdi-plus
                            </v-icon>
                            <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.700', ind)">
                              mdi-minus
                            </v-icon>
                          </div>
                        </template>
                      </s-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-for="(fld, ind) in record.fields['701']" :key="'701' + ind" dense>
                    <v-col cols="12" sm="6">
                      <span class="d-none">{{ render }}</span>
                      <s-autocomplete
                        v-model="fld.a[0].val"
                        :label="'701a ' + $t('Organizator persoane partenere')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.701.a.val'"
                        :item-value="'fields.701.a.val'"
                        collection="ebib_marc_evn"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <s-autocomplete
                        v-model="fld['4'][0].val"
                        :label="'7014 ' + $t('Funcţie')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.701.4.val'"
                        :item-value="'fields.701.4.val'"
                        collection="ebib_marc_evn"
                      >
                        <template v-slot:append-outer>
                          <div class="d-inline-flex">
                            <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.701', ind + 1)">
                              mdi-plus
                            </v-icon>
                            <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.701', ind)">
                              mdi-minus
                            </v-icon>
                          </div>
                        </template>
                      </s-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-for="(fld, ind) in record.fields['711']" :key="'711' + ind" dense>
                    <v-col cols="12" sm="6">
                      <span class="d-none">{{ render }}</span>
                      <s-autocomplete
                        v-model="fld.a[0].val"
                        :label="'711a ' + $t('Organizator instituţii partenere')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.711.a.val'"
                        :item-value="'fields.711.a.val'"
                        collection="ebib_marc_evn"
                      >
                        <template v-slot:append-outer>
                          <div class="d-inline-flex">
                            <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.711', ind + 1)">
                              mdi-plus
                            </v-icon>
                            <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.711', ind)">
                              mdi-minus
                            </v-icon>
                          </div>
                        </template>
                      </s-autocomplete>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row class="mt-4" dense>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="record.fields['610'][0].a[0].val"
                        :rules="validateValue('610', 'a')"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'610a ' + $t('Public ţintă')"
                        :items="dictionary['publicTinta']"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        v-model="record.fields['610'][0].v[0].val"
                        :rules="validateValue('610', 'v')"
                        style="width:300px"
                        :hide-details="hided"
                        :dense="dense"
                        item-text="name"
                        item-value="value"
                        :label="'610v ' + $t('Categorii de vârstă')"
                        :items="dictionary['categorieVarsta']"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-for="(fld, ind) in record.fields['702']" :key="'702' + ind" dense>
                    <v-col cols="12" sm="6">
                      <span class="d-none">{{ render }}</span>
                      <s-autocomplete
                        v-model="fld.a[0].val"
                        :label="'702a ' + $t('Persoane / personalităţi prezente')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.702.a.val'"
                        :item-value="'fields.702.a.val'"
                        collection="ebib_marc_evn"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <s-autocomplete
                        v-model="fld['4'][0].val"
                        :label="'7024 ' + $t('Funcţie')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.702.4.val'"
                        :item-value="'fields.702.4.val'"
                        collection="ebib_marc_evn"
                      >
                        <template v-slot:append-outer>
                          <div class="d-inline-flex">
                            <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.702', ind + 1)">
                              mdi-plus
                            </v-icon>
                            <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.702', ind)">
                              mdi-minus
                            </v-icon>
                          </div>
                        </template>
                      </s-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-for="(fld, ind) in record.fields['712']" :key="'712' + ind" dense>
                    <v-col cols="12" sm="6">
                      <span class="d-none">{{ render }}</span>
                      <s-autocomplete
                        v-model="fld.a[0].val"
                        :label="'712a ' + $t('Instituţii / personalităţi prezente')"
                        :dense="dense"
                        :hide-details="hided"
                        :clearable="false"
                        view-type="marc"
                        :item-text="'fields.712.a.val'"
                        :item-value="'fields.712.a.val'"
                        collection="ebib_marc_evn"
                      >
                        <template v-slot:append-outer>
                          <div class="d-inline-flex">
                            <v-icon tabindex="-1" @click.stop.prevent="addSubItem('fields.712', ind + 1)">
                              mdi-plus
                            </v-icon>
                            <v-icon tabindex="-1" @click.stop.prevent="delSubItem('fields.712', ind)">
                              mdi-minus
                            </v-icon>
                          </div>
                        </template>
                      </s-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        v-model="record.fields['215'][0].a[0].val"
                        :rules="validateValue('215', 'a')"
                        style="width:175px"
                        :hide-details="hided"
                        :dense="dense"
                        :label="'215a ' + $t('Număr participanţi')"
                      />
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item>
                  <v-row class="mt-4" dense>
                    <v-textarea
                      v-model="record.fields['330'][0].a[0].val"
                      :rules="validateValue('330', 'a')"
                      :hide-details="hided"
                      :dense="dense"
                      rows="8"
                      :label="'330a ' + $t('Rezumat')"
                    />
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-4">
                  <v-subheader>{{ "461 " + $t("Evenimente") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['461']"
                    height="75px"
                    :render="render"
                    collection="ebib_marc_evn"
                    @add="addSubItem('fields.461', $event)"
                    @del="delSubItem('fields.461', $event, 'evn')"
                    @search="searchRelationMarc('461', 't', $event, 'evn', 'eveniment')"
                  />
                  <v-subheader>{{ "462 " + $t("Subevenimente") }}</v-subheader>
                  <link-fields
                    v-model="record.fields['462']"
                    height="75px"
                    :render="render"
                    collection="ebib_marc_evn"
                    @add="addSubItem('fields.462', $event)"
                    @del="delSubItem('fields.462', $event, 'evn')"
                    @search="searchRelationMarc('462', 't', $event, 'evn', 'subeveniment')"
                  />
                </v-tab-item>
              </base-material-tabs>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
    <search-link ref="searchLink" select-event @select="setLinkValue" />
    <create-edit ref="createEdit" />
  </v-container>
</template>

<script>
import SearchLink from "../../../components/containers/SearchLink"
import CreateEdit from "../../../views/settings/AddDictionaryEdit"
import SAutocomplete from "../../../components/inputs/Autocomplete"
import SDateTime from "../../../components/inputs/DateTime"
import AddImage from "../../../components/containers/AddImage"
import { EventBus } from "../../../EventBus"
import axios from "../../../plugins/axios"
//import axio from "axios"
export default {
  name: "DashboardDashboard",
  components: {
    SearchLink,
    CreateEdit,
    SAutocomplete,
    SDateTime,
    AddImage
  },
  data() {
    return {
      dense: true,
      hided: false,
      fileName: "",
      file: {},
      urlImg: null,
      searchId: "",
      dictionary: {},
      linkArray: null,
      linkField: null,
      linkSubfield: null,
      linkIndex: null,
      selectId: null,
      render: 1,
      record: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          200: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], d: [{ ord: "", val: "" }] }],
          210: [{ ord: "", a: [{ ord: "", val: "" }], d: [{ ord: "", val: "" }], f: [{ ord: "", val: "" }] }],
          215: [{ ord: "", a: [{ ord: "", val: "" }] }],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          330: [{ ord: "", a: [{ ord: "", val: "" }] }],
          423: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          424: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          425: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          461: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          462: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          463: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          606: [{ ord: "", a: [{ ord: "", val: "" }] }],
          610: [{ ord: "", a: [{ ord: "", val: "" }], v: [{ ord: "", val: "" }] }],
          615: [{ ord: "", a: [{ ord: "", val: "" }] }],
          700: [{ 4: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          701: [{ 4: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          702: [{ 4: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          710: [{ ord: "", a: [{ ord: "", val: "" }] }],
          711: [{ ord: "", a: [{ ord: "", val: "" }] }],
          712: [{ ord: "", a: [{ ord: "", val: "" }] }],
          856: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              link: "",
              f: [{ ord: "", src_id: "", val: "" }],
              q: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      recordView: {
        company: "",
        id: "",
        ctg: "",
        fields: {
          200: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }], d: [{ ord: "", val: "" }] }],
          210: [{ ord: "", a: [{ ord: "", val: "" }], d: [{ ord: "", val: "" }], f: [{ ord: "", val: "" }] }],
          215: [{ ord: "", a: [{ ord: "", val: "" }] }],
          299: [{ ord: "", a: [{ ord: "", val: "" }] }],
          300: [{ ord: "", a: [{ ord: "", val: "" }] }],
          330: [{ ord: "", a: [{ ord: "", val: "" }] }],
          423: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          424: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          425: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          461: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          462: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          463: [{ ord: "", link: "", t: [{ ord: "", src_id: "", val: "" }] }],
          606: [{ ord: "", a: [{ ord: "", val: "" }] }],
          610: [{ ord: "", a: [{ ord: "", val: "" }], v: [{ ord: "", val: "" }] }],
          615: [{ ord: "", a: [{ ord: "", val: "" }] }],
          700: [{ 4: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          701: [{ 4: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          702: [{ 4: [{ ord: "", val: "" }], ord: "", a: [{ ord: "", val: "" }] }],
          710: [{ ord: "", a: [{ ord: "", val: "" }] }],
          711: [{ ord: "", a: [{ ord: "", val: "" }] }],
          712: [{ ord: "", a: [{ ord: "", val: "" }] }],
          856: [
            {
              0: [{ ord: "", val: "" }],
              1: [{ ord: "", val: "" }],
              ord: "",
              d: [{ ord: "", val: "" }],
              link: "",
              f: [{ ord: "", src_id: "", val: "" }],
              q: [{ ord: "", val: "" }],
              s: [{ ord: "", val: "" }],
              x: [{ ord: "", val: "" }],
              y: [{ ord: "", val: "" }],
              z: [{ ord: "", val: "" }]
            }
          ],
          916: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          917: [{ ord: "", a: [{ ord: "", val: "" }], b: [{ ord: "", val: "" }] }],
          989: [{ ord: "", a: [{ ord: "", val: "" }] }],
          "001": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "002": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "003": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "005": [{ ord: "", _: [{ ord: "", val: "" }] }],
          "006": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "007": [{ ord: "", a: [{ ord: "", val: "" }] }],
          "008": [{ ord: "", a: [{ ord: "", val: "" }] }]
        },
        unknown_fields: [{}]
      },
      schema: {
        dictionary: ["all.publicTinta", "all.categorieVarsta", "all.tipActivitate", "all.catgActivitate"]
      },
      rows: []
    }
  },

  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    if (this.schema.dictionary) {
      this.schema.dictionary.map(itm => this.getDictionaryValues(itm))
    }
    const country = []
    this.rows.map(itm => {
      country.push({ name: itm.value, value: itm.value })
    })
    EventBus.$on("dictionary-reload", dict => {
      this.$log("dictionary-reload ", dict)
      if (this.selectId == dict.id) {
        this.getDictionaryValues("all." + dict.dictionary)
        if (dict.select) {
          this.setLinkValue(dict.select)
        }
      }
    })
    // EventBus.$on("table-form-select", value => {
    //   this.$log("selected ", value)
    // })
    //this.$log(JSON.stringify(country))
    // axios.post("anaf_check_tva", [{ cui: 18158683, data: "2020-04-30" }]).then(response => {
    //   this.$log("response ", response)
    // })
  },
  methods: {
    validateValue(fld, sub) {
      const rule = []
      //rule.push(v => !!v || this.$t("required"))
      return rule
    },
    createURL(img, fil) {
      this.$set(this.dictionary, img, URL.createObjectURL(fil.target.files[0]))
    },
    fileChanged(e) {
      this.file = e.target.files[0]
      this.urlImg = URL.createObjectURL(this.file)
      this.fileName = this.file.name
    },
    clearFile() {
      this.fileName = ""
      this.file = null
    },
    createAndEdit(e, arr, field, ind) {
      //searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.selectId = Date.now()
      this.$log("createAndEdit ", e)
      this.$refs.createEdit.showData(e, this.selectId)
    },
    checkTVA(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
          }
        })
      }
    },
    loadCUIData(e) {
      if (e.length > 5) {
        const dat = new Date()
        axios.post("anaf_check_tva", [{ cui: e, data: dat.toJSON().split("T")[0] }]).then(response => {
          if (response.data.found.length > 0) {
            this.record.invoice.tva = response.data.found[0].scpTVA
            this.record.name = response.data.found[0].denumire
            const adr = response.data.found[0].adresa.split(",")
            if (adr.length > 2) {
              this.record.address.address = response.data.found[0].adresa.replace(adr[0] + "," + adr[1] + ",", "")
            }
            this.record.address.locality = adr[1]
            this.record.address.country = "România"
            if (adr[0].substr(0, 3) == "JUD") {
              const jud = adr[0].substr(5).toLowerCase()
              this.record.address.county = adr[0].substr(5)
              this.dictionary.county.map(itm => {
                if (itm.value.toLowerCase() == jud) {
                  this.record.address.county = itm.value
                }
              })
            } else {
              this.record.address.county = "Bucureşti"
            }
          }
        })
      }
    },
    getdictionary(dic) {
      return this.$store.getters.dictionary(dic)
    },
    getDictionaryValues(dic) {
      //this.$log("getDictionaryValues(itm) ", dic)
      const dictionary = dic.split(".")
      if (dictionary[0] == "all") {
        //axios.get('app_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
        this.$set(this.dictionary, dictionary[1], this.getdictionary("all_" + dictionary[1]))
        // this.dictionary[dictionary[1]] = response.data._items[0].content
        //})
      } else if (dictionary[0] == "system") {
        axios.get('app_system_dictionary?where={"value":"' + dictionary[1] + '"}').then(response => {
          this.$set(this.dictionary, dictionary[1], response.data._items[0].content)
          // this.dictionary[dictionary[1]] = response.data._items[0].content
        })
      }
    },
    addSubItem(e, ind) {
      if (ind == null) {
        this.resolvePath(e, this.record).push(JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0])))
      } else {
        this.resolvePath(e, this.record).splice(
          ind,
          0,
          JSON.parse(JSON.stringify(this.resolvePath(e, this.recordView)[0]))
        )
      }
    },
    delSubItem(e, ind) {
      this.resolvePath(e, this.record).splice(ind, 1)
      if (this.resolvePath(e, this.record).length == 0) {
        this.addSubItem(e)
      }
    },
    searchRelationMarc(field, subfield, ind, prefix, ctg) {
      this.linkField = field
      this.linkSubfield = subfield
      this.linkIndex = ind
      //this.$log("srcrel ", this.schemaContent[fields.field + subfield])
      this.searchId = Date.now()
      let where = ""
      let vTable = ""
      if (ctg) {
        where = '"ctg":' + '"' + ctg + '"'
        const ctgview = this.$store.getters.appViewTableCollectionCategory("marc_" + prefix, ctg)
        if (ctgview.length > 0) {
          vTable = ctgview[0]._id
        }
      }
      this.$refs.searchLink.open("ebib_marc_" + prefix, null, this.searchId, {
        where: where,
        viewTable: vTable,
        ctg: ctg
      })
      // EventBus.$emit("search-link", {
      //   resource: "ebib_marc_" + prefix,
      //   field: null,
      //   id: this.searchId,
      //   config: { where: where, viewTable: vTable, ctg: ctg }
      // })
      //this.$refs.searchLink.open(res, fld, this.searchId)
    },
    searchRelation(arr, field, ind, res, fld) {
      this.linkArray = arr
      this.linkField = field
      this.linkIndex = ind
      this.searchId = Date.now()
      this.$refs.searchLink.open(res, fld, this.searchId)
    },
    setLinkValue(e) {
      if (this.linkIndex != null) {
        if (this.linkField != null) {
          //this.linkArray[this.linkIndex][this.linkField] = e
          this.$set(this.linkArray[this.linkIndex], this.linkField, e)
        } else {
          if (typeof e === "object") {
            e.iiid = this.idGlobal++
          }
          if (this.linkIndex == -1) {
            this.linkArray.push(e)
          } else {
            //this.linkArray[this.linkIndex] = e
            this.$set(this.linkArray, this.linkIndex, e)
          }
        }
      } else {
        //this.linkArray[this.linkField] = e
        this.$set(this.linkArray, this.linkField, e)
        this.render = Date.now()
      }
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
